import SpinningObject from './images/spinningitem.svg'
import APR from './images/apr.svg'
import Market from './images/market.svg'
import Center from './images/center.svg'

export default function RevolvingCircle(){
    return(
        <>
        <div className = 'circle-div'>
            <div className='animated-div'>
                <img src={Market} alt='' style={{position:'absolute', height:'auto', width:'auto'}}></img>
            </div>
            <div className='animated-small-div'>
                <img src={APR} alt='' style={{position:'absolute', height:'auto', width:'auto'}}></img>
            </div>
            <div className='white-logo-div'>
                <img src={Center} alt='' style={{position:'absolute'}}></img>
            </div>
        </div>

        {/* background rotating images*/}
        <div className='spinning-item' style={{position:'absolute', top:'10%', right:'-10%',zIndex:'-1000'}}>
            <img src={SpinningObject} alt='' style={{width:'100%', height:'auto'}}/>
        </div>
        <div className='spinning-item' style={{position:'absolute', top:'100%', left:'-20%', zIndex:'-1000'}}>
            <img src={SpinningObject} alt='' style={{width:'100%', height:'auto'}}/>
        </div>
        </>
    )
}