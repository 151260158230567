export default function IndexStatistics(prop){
    return(
        <div className='index-statistics'>
            <div className = 'statistics-div'>
                <div className = 'statistic-box'>
                    <div className='index-text-box'>
                        <h1 className ='statistic-head'>
                            Total Value Locked
                        </h1>
                    </div>
                    <h1 className = 'statistic-body' style={{position:'absolute'}}>
                        ${prop.totalLocked}
                    </h1>
                </div>
            </div>
            <div className = 'statistics-div'>
                <div className = 'statistic-box'>
                    <div className="index-text-box">
                        <h1 className ='statistic-head'>
                            Total Revenue Distributed
                        </h1>
                    </div>
                    <h1 className = 'statistic-body' style={{position:'absolute'}} >
                        ${prop.totalRev}
                    </h1>
                </div>
            </div>
        </div>
    )
}