import EarnMain from "../EarnMain"
import Footer from "../Footer"
import { useEffect, useState, useRef} from 'react';
import { ethers } from 'ethers';

import artifact from '../artifacts/contracts/GSTStaking.sol/GHAstaking.json'
import ghaArtifact from '../artifacts/contracts/GhastToken.sol/Ghast.json'
import esGhaArtifact from '../artifacts/contracts/esGHA.sol/esGHA.json'
const CONTRACT_ADDRESS = '0x953F1ea7ce6Cdc9912E1c96DAC0a9fA97E8F1c31'
const GHA_ADDRESS = '0xeCA66820ed807c096e1Bd7a1A091cD3D3152cC79'
const esGHA_ADDRESS = '0x3129F42a1b574715921cb65FAbB0F0f9bd8b4f39'

export default function Earn (){
    const [input, setInput] = useState(0)
    const [ghaApproved, setGhaApproved] = useState(false)
    const [esGhaApproved, setesGhaApproved] = useState(false)
    const [ghaAllowance, setGhaAllowance] = useState(0);
    const [esGhaAllowance, setEsGhaAllowance] = useState(0);
    const [signer, setSigner] = useState(undefined);
    const [contract, setContract] = useState(undefined);
    const [signerAddress, setSignerAddress] = useState(undefined);
    const [ghaCoin, setGHACoin] = useState(undefined);
    const [esGhaCoin, setesGHACoin] = useState(undefined);
    const [amount, setAmount] = useState(0);
    const [pool, setPool] = useState(3);
    const [userInfo, setUserInfo] = useState({maxStake0: 0, maxStaked0: 0, maxStake1: 0, maxStaked1: 0});
    
    useEffect(() => {
        const onLoad = async () => {

            const provider = new ethers.providers.Web3Provider(window.ethereum);

            const alchemyProvider = new ethers.providers.AlchemyProvider("arbitrum", process.env.REACT_APP_L2_RPC);

            const alchemysigner = new ethers.Wallet(process.env.REACT_APP_PRIVKEY, alchemyProvider);

            const contract = new ethers.Contract(
                CONTRACT_ADDRESS,
                artifact.abi,
                alchemysigner
            );
            
            setContract(contract);

            const ghaCoin = new ethers.Contract(
                GHA_ADDRESS,
                ghaArtifact.abi,
                alchemysigner
            );
            
            setGHACoin(ghaCoin);

            const esGhaCoin = new ethers.Contract(
                esGHA_ADDRESS,
                esGhaArtifact.abi,
                alchemysigner
            );
            
            setesGHACoin(esGhaCoin);

            try {
                getSigner(provider)
                .then(signer => {
                    setSigner(signer)
                })
            } catch (ex) {
                console.log(ex)
            }

            if (signerAddress) {
                const promise = [
                    ghaCoin.connect(signerAddress).balanceOf(signerAddress),
                    esGhaCoin.connect(signerAddress).balanceOf(signerAddress),
                    contract.userInfo(0, signerAddress),
                    contract.userInfo(1, signerAddress)
                ]

                Promise.all(promise)
                    .then((results) => {
                        const userGha = ethers.utils.formatEther(results[0])
                        const ghaStaked = ethers.utils.formatEther(results[2].amount);
                        const userEsGha = ethers.utils.formatEther(results[1]);
                        const esGhaStaked = ethers.utils.formatEther(results[3].amount);
                        console.log(userGha, ghaStaked, userEsGha, esGhaStaked)
                        setUserInfo({maxStake0: userGha, maxStaked0: ghaStaked, maxStake1: userEsGha, maxStaked1: esGhaStaked});
                    })

                allowanceUpdate();
            }
        }
        onLoad();
    }, [signerAddress])

    const allowanceUpdate = () =>{
        Promise.all([
            ghaCoin.connect(signerAddress).allowance(signerAddress, CONTRACT_ADDRESS).then((v)=>setGhaAllowance(ethers.utils.formatEther(v))),
            esGhaCoin.connect(signerAddress).allowance(signerAddress, CONTRACT_ADDRESS).then((v)=>setEsGhaAllowance(ethers.utils.formatEther(v))),
        ]).then((v)=>{}).catch((e)=>{console.log(e)});
    }

    useEffect(() => {
        if (contract) {
            ghaCoin.on("Approval", allowanceUpdate);
            ghaCoin.on("Approval", allowanceUpdate);
        }
        return () => {
            if (contract) {
                esGhaCoin.off("Approval", allowanceUpdate);
                esGhaCoin.off("Approval", allowanceUpdate);
            }
        };
    }, [ghaCoin, esGhaCoin]);

    useEffect(() => {
        if (amount) {
            const ghaApproved =  input !== "Staking" || parseFloat(ghaAllowance) >= amount
            setGhaApproved(ghaApproved);
            const esGhaApproved =  input !== "Staking" || parseFloat(esGhaAllowance) >= amount
            setesGhaApproved(esGhaApproved);
        } else {
            setGhaApproved(true);
            setesGhaApproved(true);
        }
    }, [amount, input, signerAddress])

    const getSigner = async provider => {
        const signer = await provider.getSigner();

        signer.getAddress()
        .then((address) => {
            setSignerAddress(address)
        })

        return signer;
    }

    const callFunction = async () => {
        try {
            if (input === "Staking") {
                await contract.connect(signer).deposit(pool,  ethers.utils.parseEther(amount));
            } else if (input === "Unstaking") {
                await contract.connect(signer).withdraw(pool, ethers.utils.parseEther(amount));
            } else if (input === "Claim ETH and Compound RP") {
                await contract.connect(signer).deposit(pool, 0);
            }
        } catch (e) {
            alert(e.reason);
        }
        setInput(0)
    }

    const approve = async () => {
        if (!pool) {
            await ghaCoin.connect(signer).approve(CONTRACT_ADDRESS, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');
            const ghaAllowance = await ghaCoin.connect(signerAddress).allowance(signerAddress, CONTRACT_ADDRESS);
            const ghaApproved = input !== "Staking" || ethers.utils.formatEther(ghaAllowance) >= amount
            setGhaApproved(ghaApproved);
        } else {
            await esGhaCoin.connect(signer).approve(CONTRACT_ADDRESS, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');
            const esGhaAllowance = await esGhaCoin.connect(signerAddress).allowance(signerAddress, CONTRACT_ADDRESS);
            const esGhaApproved =  input !== "Staking" || ethers.utils.formatEther(esGhaAllowance) >= amount
            setesGhaApproved(esGhaApproved);
        }
    };
    
    const ref = useRef();
    const scrollToElement = () => {
        ref.current?.scrollIntoView({behavior:"smooth", block: "center", inline:"nearest"});
    };
    useEffect(() => {
        scrollToElement();
    }, []);
    return(
        <div className="earn-div">
            <EarnMain stake={()=> {setInput("Staking"); setPool(0); setAmount(0)}} claim={()=> {setInput("Claim ETH and Compound RP"); setPool(0); setAmount(0)}} 
                    unstake={()=> {setInput("Unstaking"); setPool(0); setAmount(0)}} esstake={()=> {setInput("Staking"); setPool(1); setAmount(0)}} esclaim={()=> {setInput("Claim ETH and Compound RP"); setPool(1); setAmount(0)}} 
                    esunstake={()=> {setInput("Unstaking"); setPool(1); setAmount(0)}}/>
            { input !== 0 &&
            <div style={{position:'absolute', display:'flex', alignItems:'center', justifyContent:'center', width:'100%', top:'55%'}} ref={ref}>
                <div className="earn-popup">
                    <div className="earn-popup-sub">
                        <div className="earn-popup-head"> 
                            {input}
                        </div>
                        <div className="earn-popup-text">
                            <div className="earn-input-sub">
                                { input === "Staking" && 
                                <>Stake</>}
                                { input === "Unstaking" && 
                                <>Unstake</>}
                                { input !== "Claim ETH and Compound RP" &&
                                <><input className="earn-input" onFocus={e => e.target.select()} onChange={e => setAmount(e.target.value)} value={amount}/></>}
                            </div>
                            <div className="earn-input-sub">
                                <div style={{width:'100%', display:'flex', justifyContent:'right'}}> 
                                    { pool === 0 && 
                                    <>
                                        { input === "Staking" && 
                                        <button onClick={()=> setAmount(userInfo.maxStake0)} className="market-input-field">Max {userInfo.maxStake0}</button>}
                                        { input === "Unstaking" && 
                                        <button onClick={()=> setAmount(userInfo.maxStaked0)} className="market-input-field">Max {userInfo.maxStaked0}</button>}
                                    </>
                                    }
                                    { pool === 1 && 
                                    <>
                                        { input === "Staking" && 
                                        <button onClick={()=>setAmount(userInfo.maxStake1)} className="market-input-field">Max {userInfo.maxStake1}</button>}
                                        { input === "Unstaking" && 
                                        <button onClick={()=>setAmount(userInfo.maxStaked1)} className="market-input-field">Max {userInfo.maxStaked1}</button>}
                                    </>
                                    }
                                </div>
                                { input !== "Claim ETH and Compound RP" && 
                                <><div style={{width:'100%', display:'flex', justifyContent:'right', color:'white', fontWeight:'bold', marginTop:'8px'}}>
                                    { pool === 0 && <>GHA</>}
                                    { pool === 1 && <>esGHA</>}
                                </div></>}
                            </div>
                        </div>
                            <div className="market-input-buttons">
                            { pool === 0 && 
                            <>
                                { ghaApproved ?
                                <button onClick={()=>{ callFunction() }} className="earn-input-btn">
                                    { input === "Staking" && 
                                    <>Stake</>}
                                    { input === "Claim ETH and Compound RP" && 
                                    <>Claim</>}
                                    { input === "Unstaking" && 
                                    <>Unstake</>}
                                </button>:
                                <button className="earn-input-btn" onClick={()=>{approve() }}>
                                    Approve
                                </button>
                                }
                            </>}
                            { pool === 1 && 
                            <>
                                { esGhaApproved ?
                                    <button onClick={()=>{ callFunction() }} className="earn-input-btn">
                                        { input === "Staking" && 
                                        <>Stake</>}
                                        { input === "Claim ETH and Compound RP" && 
                                        <>Claim</>}
                                        { input === "Unstaking" && 
                                        <>Unstake</>}
                                    </button>:
                                    <button className="earn-input-btn" onClick={()=>{approve()}}>
                                        Approve
                                    </button>
                                }
                            </>
                            }
                        <button className="earn-input-btn" onClick={()=> {setInput(0); setAmount(0)}}> Cancel </button>
                        </div>
                    </div>
                </div>
            </div>}
            <Footer pageName="earn"/>
        </div>
    )
}