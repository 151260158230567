export default function IndexText(){
    return (
        <div className = 'index-text'>
            <h1 className = 'index-header'>
                Ghast Protocol
            </h1>
            <h1 className = 'index-sub'>
                Liquidation-Free Money Market
            </h1>
            <div>
                <p className="index-body">
                    Using Enhanced Pseudo-Delta-Neutral strategies 
                    combined with liquidation-free money-market strategies 
                    for yield-bearing tokens to unlock institution-grade yield 
                    optimization and seamless capital efficiency.
                </p>
            </div>
        </div>
    )
}