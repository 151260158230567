import { useNavigate } from "react-router"



export default function IndexButtons(){
    {/* navigate to new path */}
    let navigate = useNavigate()
    const routePresale = () => {
        let path = 'presale'
        navigate(path)
    }

    return(
        <div className='index-btns'>
            <a className ='early-whitelist'> Early Whitelist </a>
            <button onClick={routePresale} className ='whitelist'> Presale </button>
            <a className='whitelist' href="https://ghast-protocol.gitbook.io/ghast-protocol/protocol-overview/introducing-ghast-protocol" target="_blank" rel="noreferrer"> Docs </a>
        </div>
    )
}