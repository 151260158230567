import DISC from './images/discord.svg'
import GIT from './images/github.svg'
import TWT from './images/twitter.svg'
import M from './images/m.svg'
import Logo from './images/logo.svg'
import GST from './images/GHA.svg'


export default function Footer(pageName){
    return(
        <>
        { pageName.pageName === 'index' && 
        <div className='index-footer' style={{position: 'absolute', top:'420%'}}>
            <div className='footer-logo'>
                <img src={Logo} alt='' style={{width:'29.5%', height:'auto'}} />
                <img src={GST} alt='' style={{width:'62.73%', height:'auto'}} />
            </div>
            <div className='footer-icons'>
                <a href='https://discord.gg/E72YBCt36H' target="_blank" rel="noreferrer" className='footer-git'>
                    <img src={DISC} alt='' style={{width:'100%', height:'auto'}} />
                </a>
                <a href='https://ghast-protocol.gitbook.io/ghast-protocol/protocol-overview/introducing-ghast-protocol' target="_blank" rel="noreferrer" className='footer-git'>
                    <img src={GIT} alt='' style={{width:'80%', height:'auto'}} />
                </a>
                <a href='https://twitter.com/GhastProtocol' target="_blank" rel="noreferrer" className='footer-twitter'>
                    <img src={TWT} alt='' style={{width:'100%', height:'auto'}}/>
                </a>
                <a href='https://medium.com/@ghastprotocol' target="_blank" rel="noreferrer" className='footer-medium'>
                    <img src={M} alt='' style={{width:'100%', height:'auto'}}/>
                </a>
            </div>
        </div>
    }
    { pageName.pageName === 'presale' && 
        <div className='footer' style={{position: 'absolute', top:'105%'}}>
            <div className='footer-icons'>
                <a href='https://discord.gg/E72YBCt36H' target="_blank" rel="noreferrer" className='footer-git'>
                    <img src={DISC} alt='' style={{width:'100%', height:'auto'}} />
                </a>
                <a href='https://ghast-protocol.gitbook.io/ghast-protocol/protocol-overview/introducing-ghast-protocol' target="_blank" rel="noreferrer" className='footer-git'>
                    <img src={GIT} alt='' style={{width:'80%', height:'auto'}} />
                </a>
                <a href='https://twitter.com/GhastProtocol' target="_blank" rel="noreferrer" className='footer-twitter'>
                    <img src={TWT} alt='' style={{width:'100%', height:'auto'}}/>
                </a>
                <a href='https://medium.com/@ghastprotocol' target="_blank" rel="noreferrer" className='footer-medium'>
                    <img src={M} alt='' style={{width:'100%', height:'auto'}}/>
                </a>
            </div>
        </div>
    }
    { pageName.pageName === 'market' && 
        <div className='footer' style={{position: 'relative', top:'15%'}}>
            <div className='footer-logo'>
                <img src={Logo} alt='' style={{width:'29.5%', height:'auto'}} />
                <img src={GST} alt='' style={{width:'62.73%', height:'auto'}} />
            </div>
            <div className='footer-icons'>
                <a href='https://discord.gg/E72YBCt36H' target="_blank" rel="noreferrer" className='footer-git'>
                    <img src={DISC} alt='' style={{width:'100%', height:'auto'}} />
                </a>
                <a href='https://ghast-protocol.gitbook.io/ghast-protocol/protocol-overview/introducing-ghast-protocol' target="_blank" rel="noreferrer" className='footer-git'>
                    <img src={GIT} alt='' style={{width:'80%', height:'auto'}} />
                </a>
                <a href='https://twitter.com/GhastProtocol' target="_blank" rel="noreferrer" className='footer-twitter'>
                    <img src={TWT} alt='' style={{width:'100%', height:'auto'}}/>
                </a>
                <a href='https://medium.com/@ghastprotocol' target="_blank" rel="noreferrer" className='footer-medium'>
                    <img src={M} alt='' style={{width:'100%', height:'auto'}}/>
                </a>
            </div>
        </div>
    }
    { pageName.pageName === 'marketv2' && 
        <div className='footer' style={{position: 'relative', top:'15%'}}>
            <div className='footer-logo'>
                <img src={Logo} alt='' style={{width:'29.5%', height:'auto'}} />
                <img src={GST} alt='' style={{width:'62.73%', height:'auto'}} />
            </div>
            <div className='footer-icons'>
                <a href='https://discord.gg/E72YBCt36H' target="_blank" rel="noreferrer" className='footer-git'>
                    <img src={DISC} alt='' style={{width:'100%', height:'auto'}} />
                </a>
                <a href='https://ghast-protocol.gitbook.io/ghast-protocol/protocol-overview/introducing-ghast-protocol' target="_blank" rel="noreferrer" className='footer-git'>
                    <img src={GIT} alt='' style={{width:'80%', height:'auto'}} />
                </a>
                <a href='https://twitter.com/GhastProtocol' target="_blank" rel="noreferrer" className='footer-twitter'>
                    <img src={TWT} alt='' style={{width:'100%', height:'auto'}}/>
                </a>
                <a href='https://medium.com/@ghastprotocol' target="_blank" rel="noreferrer" className='footer-medium'>
                    <img src={M} alt='' style={{width:'100%', height:'auto'}}/>
                </a>
            </div>
        </div>
    }
    { pageName.pageName === 'dashboard' && 
        <div className='footer' style={{position: 'relative', top:'5%'}}>
            <div className='footer-logo'>
                <img src={Logo} alt='' style={{width:'29.5%', height:'auto'}} />
                <img src={GST} alt='' style={{width:'62.73%', height:'auto'}} />
            </div>
            <div className='footer-icons'>
                <a href='https://discord.gg/E72YBCt36H' target="_blank" rel="noreferrer" className='footer-git'>
                    <img src={DISC} alt='' style={{width:'100%', height:'auto'}} />
                </a>
                <a href='https://ghast-protocol.gitbook.io/ghast-protocol/protocol-overview/introducing-ghast-protocol' target="_blank" rel="noreferrer" className='footer-git'>
                    <img src={GIT} alt='' style={{width:'80%', height:'auto'}} />
                </a>
                <a href='https://twitter.com/GhastProtocol' target="_blank" rel="noreferrer" className='footer-twitter'>
                    <img src={TWT} alt='' style={{width:'100%', height:'auto'}}/>
                </a>
                <a href='https://medium.com/@ghastprotocol' target="_blank" rel="noreferrer" className='footer-medium'>
                    <img src={M} alt='' style={{width:'100%', height:'auto'}}/>
                </a>
            </div>
        </div>
    }
    { pageName.pageName === 'earn' && 
        <div className='footer' style={{position: 'relative', top:'5%'}}>
            <div className='footer-logo'>
                <img src={Logo} alt='' style={{width:'29.5%', height:'auto'}} />
                <img src={GST} alt='' style={{width:'62.73%', height:'auto'}} />
            </div>
            <div className='footer-icons'>
                <a href='https://discord.gg/E72YBCt36H' target="_blank" rel="noreferrer" className='footer-git'>
                    <img src={DISC} alt='' style={{width:'100%', height:'auto'}} />
                </a>
                <a href='https://ghast-protocol.gitbook.io/ghast-protocol/protocol-overview/introducing-ghast-protocol' target="_blank" rel="noreferrer" className='footer-git'>
                    <img src={GIT} alt='' style={{width:'80%', height:'auto'}} />
                </a>
                <a href='https://twitter.com/GhastProtocol' target="_blank" rel="noreferrer" className='footer-twitter'>
                    <img src={TWT} alt='' style={{width:'100%', height:'auto'}}/>
                </a>
                <a href='https://medium.com/@ghastprotocol' target="_blank" rel="noreferrer" className='footer-medium'>
                    <img src={M} alt='' style={{width:'100%', height:'auto'}}/>
                </a>
            </div>
        </div>
    }
    </>

    )
}