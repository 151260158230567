export default function MarketInfo(prop){
    // console.log(prop);
    return(
        <div className="market-info-div">
            <div className="market-info">
                <div className="market-stat-box">
                    <h1 className="market-stat-head">
                        Total Supply
                    </h1>
                    <div className="market-stat">
                        ${parseFloat((parseFloat(prop.supply).toFixed(2))).toLocaleString("en-US")}
                    </div>
                </div>
                <div className="market-stat-box">
                    <h1 className="market-stat-head">
                        Total Borrow
                    </h1>
                    <div className="market-stat">
                        ${parseFloat((parseFloat(prop.borrow).toFixed(2))).toLocaleString("en-US")}
                    </div>
                </div>
                <div className="market-stat-box">
                    <h1 className="market-stat-head">
                        Avg. Supply APR
                    </h1>
                    <div className="market-stat">
                        {(parseFloat(prop.supplyAPR)).toFixed(2)}%
                    </div>  
                </div>
                <div className="market-stat-box">
                    <h1 className="market-stat-head">
                        Avg. Borrow APR
                    </h1>
                    <div className="market-stat">
                        {parseFloat(prop.borrowAPR).toFixed(2)}%
                    </div>
                </div>
            </div>
        </div>
    )
}