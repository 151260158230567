import './App.css';

import Index from './pages/Index.js'
import Presale from './pages/Presale.js'
import Market from './pages/Market';
import Marketv2 from './pages/Marketv2';
import Dashboard from './pages/Dashboard';
import Earn from './pages/Earn';

import {Route, Routes} from 'react-router-dom'


export default function App() {
  document.body.style='background: #0D0D0E; overflow-x:hidden !important;';
  return (
    <>
    <Routes>
      <Route path="/" element= {<Index/>}/>
      <Route path="/presale" element ={<Presale/>}/>
      <Route path="/market" element ={<Market/>}/>
      <Route path="/marketv2" element ={<Marketv2/>}/>
      <Route path="/dashboard" element= {<Dashboard/>}/>
      <Route path="/earn" element={<Earn/>}/>
    </Routes>
    </>
  )
}
