import GhastLogo from './images/logo.svg'
import { useNavigate } from 'react-router-dom'
export default function MainNavbar(prop) {
    let market_navigate = useNavigate()
    const routeMarket = () => {
        let path = "/market"
        market_navigate(path)
    }
    const routeMarketV2 = () => {
        let path = "/marketv2"
        market_navigate(path)
    }
    const routeHome = () => {
        let path = "/"
        market_navigate(path)
    }
    const routeDash = () => {
        let path = "/dashboard"
        market_navigate(path)
    }
    const routeEarn = () => {
        let path = "/earn"
        market_navigate(path)
    }
    return(
        <div className='main-navbar'>
            <div className='main-navbar-content'>
                <button className='main-nav-logo-div' onClick={routeHome}>
                    <img src={GhastLogo} alt = 'GhastLogo' style={{height:'100%', width:'auto'}}></img>
                </button>
                <div className='main-nav-buttons'>
                    { prop.pageName === "dashboard" ? 
                    <button className='main-nav-selected'>
                        Dashboard 
                    </button>:
                    <button className='main-nav-btn' onClick={routeDash}>
                        Dashboard 
                    </button>
                    }
                    { prop.pageName === "market" ? 
                    <button className='main-nav-selected'>
                        Markets
                    </button>:
                    <button className='main-nav-btn' onClick={routeMarket}>
                        Markets
                    </button>
                    }
                    { prop.pageName === "marketv2" ? 
                    <button className='main-nav-selected'>
                        v2 Markets
                    </button>:
                    <button className='main-nav-rainbow' onClick={routeMarketV2}>
                        v2 Markets
                    </button>
                    }
                    { prop.pageName === "earn" ? 
                    <button className='main-nav-selected'>
                        Earn
                    </button>:
                    <button className='main-nav-btn' onClick={routeEarn}>
                        Earn
                    </button>
                    }
                    <a className='main-nav-btn' href="https://ghast-protocol.gitbook.io/ghast-protocol/protocol-overview/introducing-ghast-protocol" target="_blank" rel="noreferrer"> 
                        Docs 
                    </a>
                </div>
                <button className='connect-wallet' onClick={prop.onClick}>
                    {prop.buttonText}
                </button>
            </div>
        </div>
    )
}