import USDC from './images/USDCCoin.svg';
import ETH from './images/ETHCoin.svg';
import BTC from './images/BTCCoin.svg';
import USDT from './images/USDT.svg';
import GND from './images/GND.svg'

export default function DashboardStat(prop){
    return (
        <div className="dashboard-index-coin">
            <div className="dashboard-index-sub">
                <div>
                    { prop.coin === "USDC" && <> <img src={USDC} alt="" style={{marginRight:'5px'}}/> USDC </>}
                    { prop.coin === "BTC" && <> <img src={BTC} alt="" style={{marginRight:'5px'}}/> Bitcoin </>}
                    { prop.coin === "ETH" && <> <img src={ETH} alt="" style={{marginRight:'5px'}}/> Ethereum </>}
                    { prop.coin === "USDT" && <> <img src={USDT} alt="" style={{marginRight:'5px'}}/> USDT </>}
                    { prop.coin === "GND" && <> <img src={GND} alt="" style={{marginRight:"5px"}}/> GND </>}
                </div>
                <div className='dashboard-coin-stats'>
                    <div className='dashboard-stat'>
                        <div>
                            ${parseFloat(parseFloat(prop.price).toFixed(2)).toLocaleString('en-US')}
                        </div>
                    </div>
                    <div className='dashboard-stat'>
                        { prop.coin === "USDC" && <span> {parseFloat(prop.supply).toFixed(2)} USDC  </span>}
                        { prop.coin === "BTC" && <span> {parseFloat(prop.supply).toFixed(2)} WBTC </span>}
                        { prop.coin === "ETH" && <span> {parseFloat(prop.supply).toFixed(2)} WETH  </span>}
                        { prop.coin === "USDT" && <span>{parseFloat(prop.supply).toFixed(2)} USDT  </span>}
                        { prop.coin === "GND" && <span> {parseFloat(prop.supply).toFixed(2)} GND </span>}
                    </div>
                    <div className='dashboard-stat'>
                        { prop.coin === "USDC" && <span> {parseFloat(prop.borrow).toFixed(2)} USDC  </span>}
                        { prop.coin === "BTC" && <span> {parseFloat(prop.borrow).toFixed(2)} WBTC </span>}
                        { prop.coin === "ETH" && <span> {parseFloat(prop.borrow).toFixed(2)} WETH  </span>}
                        { prop.coin === "USDT" && <span> {parseFloat(prop.borrow).toFixed(2)} USDT  </span>}
                        { prop.coin === "GND" && <span> {parseFloat(prop.borrow).toFixed(2)} GND </span>}
                    </div>
                </div>
            </div>
        </div>
    )
}