import Footer from "../Footer"
import PresaleBackground from "../PresaleBackground"
import PresaleMain from "../PresaleMain";


export default function Presale () {
    return (
        <div className="presale-div">
            <Footer pageName = 'presale'/>
            <PresaleBackground/>
            <PresaleMain/>
            <div className="presale-details">
                <h1 className="presale-details-head"> GHAST PROTOCOL </h1>
                <h1 className="presale-details-sub"> Early Whitelist Presale! </h1>
                <p className="presale-details-date"> May 13th, 2023 4:00 PM UTC</p>
            </div>
        </div>
    )
}