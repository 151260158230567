import BImage1 from './images/ghastbackground1.svg'
import BImage2 from './images/ghastbackground2.svg'
import BImage3 from './images/ghastbackground3.svg'
import BImage4 from './images/ghastbackground4.svg'
export default function BackgroundIcons(){
    return(
        <div>
            <img src={BImage1} alt='' className='background-img' style={{position:'absolute', top:'270%', left:'15%'}} />
            <img src={BImage2} alt='' className='background-img' style={{position:'absolute', top:'275%', left:'70%'}}/>
            <img src={BImage3} alt=''className='background-img' style={{position:'absolute', top:'320%',left:'30%'}}/>
            <img src={BImage4} alt=''className='background-img' style={{position:'absolute', top:'315%', left:'55%'}}/>
        </div>
    )
}