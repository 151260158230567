import Yield from './images/yield.svg'
import Compound from './images/compound.svg'
import Liquidation from './images/liquidation.svg'


export default function Featured(){
    return(
        <div style={{display:'flex', justifyContent:'center'}}>
            <div className='featured-div'>
                <div className='featured-head'>
                FEATURED
                </div>
                <div className='featured-info'>
                    <div className='featured-gst'>
                        <h1 className='featured-header-text'>
                            Money Market for gmdTOKENS
                        </h1>
                        <h1 className='featured-text'>
                            Boost $ GLP yield by 195% with leveraged looping strategies 
                        </h1>
                    </div>
                </div>
                <div className='featured-aspect'>
                    <div className='aspect-element-yield'>
                        <img src={Yield} style={{marginRight:'15px', height:'120%', width:'auto'}} alt=''/>
                        <h1 className='aspect-text'>
                            #RealYield
                        </h1>
                    </div>
                    <div className='aspect-element-comp-yield'>
                        <img src={Compound} style={{marginRight:'15px', height:'120%', width:'auto'}} alt=''/>
                        <h1 className='aspect-text'>
                            Auto-Compounding Yield
                        </h1>
                    </div>
                    <div className='aspect-element-liquidation'>
                        <img src={Liquidation} style={{marginRight:'15px', height:'120%', width:'auto'}} alt=''/>
                        <h1 className='aspect-text'>
                            No Liquidation Risk
                        </h1>
                    </div>
                </div>
            </div>
        </div>  
    )
}