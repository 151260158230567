import Arbitrum from './images/arbitrum.svg'
import USDC from './images/usdctoken.svg'
import ETH from './images/ethtoken.svg'
import BTC from './images/bitcointoken.svg'
import GST from './images/ghast.svg'
import Standard from './images/standard_logo.svg'
import GMX from './images/GMX.svg'
import USDT from './images/USDTCoin.png'




export default function Ecosystem(){
    return(
        <div className='eco'>
            <div className='eco-div'>
                <h1 className='eco-title'>
                    ECOSYSTEM
                </h1>
                <h1 className='eco-info'>
                    Tokens •  Chains   •   Partners
                </h1>
                <div className='eco-coins'>
                    <div style={{width:'90%', height:'100px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <img src={USDT} alt='' style={{width:'11%', height:'auto'}}/>
                        <img src={USDC} alt='' style={{width:'11%', height:'auto'}}/>
                        <img src={ETH} alt='' style={{width:'11%', height:'auto'}}/>
                        <img src={BTC} alt='' style={{width:'11%', height:'auto'}}/>
                        <img src={Standard} alt='' style={{width:'13%', height:'auto'}}/>
                        <img src={Arbitrum} alt='' style={{width:'11%', height:'auto'}} />
                        <img src={GMX} alt='' style={{width:'11%', height:'auto'}}/>
                        <img src={GST} alt='' style={{width:'11%', height:'auto'}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}