import USDC from './images/USDCCoin.svg'
import BTC from './images/BTCCoin.svg'
import ETH from './images/ETHCoin.svg'
import USDT from './images/USDT.svg'
import GND from './images/GND.svg'
export default function MarketCoinInfo(prop){
    return(
        <>
        <div className="coin-market-div">
            <button className = "coin-market-info" onClick={prop.onClick}>
                <div className='coin-market-img'>
                    { prop.divName === "USDC" &&
                    <> <img src={USDC} style={{margin:'8px'}} alt = ''/> USDC </>}
                    { prop.divName === "USDC.e" &&
                    <> <img src={USDC} style={{margin:'8px'}} alt = ''/> USDC.e </>}
                    { prop.divName === "BTC" && 
                    <> <img src={BTC} style={{margin:'8px'}} alt=''/> WBTC </>}
                    { prop.divName === "ETH" && 
                    <> <img src={ETH}  style={{margin:'8px'}} alt=''/> WETH </>}
                    { prop.divName === "USDT" &&
                    <> <img src={USDT} style={{margin:'8px'}}/> USDT </>}
                    { prop.divName === "GND" &&
                    <> <img src={GND} style={{width:'38', height:'auto'}}/> xGND </>}
                </div>
                <div className='coin-market-sub'>
                    <p>
                        Collateral 
                    </p>
                    <p>
                        Total Supply
                    </p>
                    <p>
                        Total Borrow
                    </p>
                    <p>
                        Supply APR
                    </p>
                    <p>
                        Borrow APR 
                    </p>
                </div>
                <div className="gst-coin-div">
                    <div className='gst-sizing'>
                        <div className='market-coin-image'>
                            { (prop.divName === "USDC" || prop.divName === "USDC.e") &&
                            <> <img src="https://app.tender.fi/images/coin-icons/gmdUSDC.png" className='market-info-image' alt=''/>
                            gmdUSDC </>}
                            { prop.divName === "BTC" && 
                            <> <img src="https://app.tender.fi/images/coin-icons/gmdBTC.png" className='market-info-image' alt=''/>
                            gmdBTC</>}
                            { prop.divName === "ETH" && 
                            <> <img src="https://app.tender.fi/images/coin-icons/gmdETH.png" className='market-info-image' alt=''/>
                            gmdETH </>}
                            { prop.divName === "USDT" &&
                            <> <img src="https://app.tender.fi/images/coin-icons/gmdUSDT.png" className='market-info-image' alt=''/>
                            gmdUSDT</>}
                            { prop.divName === "GND" &&
                            <> <img src={GND} className='market-info-image' alt=''/>
                            <span style={{marginRight:'25px'}}>GND</span>
                            </>}
                        
                        </div>
                        <div className='market-info-stats'>
                            ${parseFloat(parseFloat(prop.supply).toFixed(2)).toLocaleString("en-US")}
                        </div>
                        <div className='market-info-stats'>
                            ${parseFloat(parseFloat(prop.borrow).toFixed(2)).toLocaleString("en-US")}
                        </div>
                        <div className='market-info-stats'>
                            {parseFloat(prop.supplyAPR).toFixed(2)}%
                        </div>
                        <div className='market-info-stats'>
                            {parseFloat(prop.borrowAPR).toFixed(2)}%
                        </div>
                    </div>
                </div>
            </button>
        </div>
        &nbsp;
    </>
    )
}